
import Vue from "vue";
import store from "@/store/index";
import moment from "moment-timezone";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IIngredientFryingReport, IIngredientFryingReportEntry } from "@common/reports";
import { IIngredientFryingStats, IIngredientFryingStatsFilter } from "@common/ingredientFrying";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import { IStation } from "@common/station";
import { TDocumentDefinitions } from "pdfmake/interfaces";

export default Vue.extend({
	name: "IngredientFryingReport",
	data: () => {
		return {
			date: "",
			iframeURL: "",
			gasStation: {} as IStation,
			gasStations: [] as IStation[],
		};
	},
	created: async function () {
		// Set pdfmake fonts
		pdfMake.vfs = pdfFonts.pdfMake.vfs;

		this.date = this.currentDate;
		try {
			const options: AxiosRequestConfig = {
				method: "GET",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				},
				url: `${store.getters.serverURL}/station/all`,
			};
			const res: AxiosResponse<IServerRES<IStation[]>> = await axios(options);
			if (res.data.err === ServerError.NO_ERROR) {
				this.gasStations = res.data.payload.map((item) => {
					item.name = `(${item.id}) ${item.name}`;

					return item;
				});
				if (this.gasStations.length) {
					this.gasStation = this.gasStations[0];
				}
			}
		} catch (err) {
			console.error(err);
		}
	},
	computed: {
		currentDate () {
			return moment().format("yyyy-MM-DD");
		}
	},
	methods: {
		async fetchData (): Promise<IIngredientFryingStats[]> {
			try {
				const data: IIngredientFryingStatsFilter = {
					date: {
						from: moment(this.date).startOf("day").utc().toDate(),
						until: moment(this.date).endOf("day").utc().toDate()
					}
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${this.store.getters.serverURL}/ingredient-frying/stats/${this.gasStation.id}`,
				};
				const res: AxiosResponse<IServerRES<IIngredientFryingStats[]>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					const { payload } = res.data;

					return payload;
				}
			} catch (err) {
				console.error(err);
			}

			return [];
		},
		async generateReportData (): Promise<IIngredientFryingReport> {
			const ingredientFryingStats = await this.fetchData();
			const report: IIngredientFryingReport = [];

			for (const item of ingredientFryingStats) {
				const entry: IIngredientFryingReportEntry = {
					date: item.date,
					ingredientName: item.ingredientName ? item.ingredientName : "-",
					productTitle: item.productTitle ? item.productTitle : "-",
					batch: item.batch,
					processingTemperature: `${item.tempMin} - ${item.tempMax}`,
					processingTime: item.processingTime,
					temperatureAfterProcessing: item.temperatureAfterProcessing,
					responsible: item.operatorName ? item.operatorName : "-",
				};

				if (item.qty === undefined || item.qty === null) {
					item.qty = 1;
				}

				for (let i = 0; i < item.qty; i++) {
					report.push(entry);
				}
			}

			return report;
		},
		generateIngredientFryingTable (ingredientFryingReport: IIngredientFryingReport) {
			const table = {
				headerRows: 1,
				widths: [50, 100, 100, "auto", "auto", "auto", "auto", "auto"],
				body: [
					[
						{
							text: "Ora",
							alignment: "center"
						},
						{
							text: "Materie primă",
							alignment: "center"

						},
						{
							text: "Produs finit",
							alignment: "center"
						},
						{
							text: "Nr. Lot producator a materiei prime tratate termic NIR",
							alignment: "center"
						},
						{
							text: "Temperatura de prelucrare termică(TºC)",
							alignment: "center"
						},
						{
							text: "Durata de menţinere a temperaturii la prelucrare termică (min.)",
							alignment: "center"
						},
						{
							text: "Temperatura produsului dupa prelucrarea termica*",
							alignment: "center"
						},
						{
							text: "Responsabil",
							alignment: "center"
						}
					]
				]
			};

			for (const entry of ingredientFryingReport) {
				table.body.push([
					{
						text: moment(entry.date).format("HH:mm"),
						alignment: "center"
					},
					{
						text: entry.ingredientName,
						alignment: "center"
					},
					{
						text: entry.productTitle,
						alignment: "center"
					},
					{
						text: entry.batch,
						alignment: "center"
					},
					{
						text: entry.processingTemperature,
						alignment: "center"
					},
					{
						text: entry.processingTime.toString(),
						alignment: "center"
					},
					{
						text: entry.temperatureAfterProcessing.toString(),
						alignment: "center"
					},
					{
						text: entry.responsible,
						alignment: "center"
					}
				]);
			}

			return table;
		},
		async generatePDF () {
			const report = await this.generateReportData();

			const docDefinition: TDocumentDefinitions = {
				pageOrientation: "landscape",
				content: [
					{
						text: `STAȚIA: ${this.gasStation.name.replace(`(${this.gasStation.id})`, "").trim()}`,
						fontSize: 10
					},
					{
						text: "Acest formular se completează doar pentru produsele de origine animală.",
						fontSize: 10
					},
					{
						text: "V1_2021",
						alignment: "right",
						fontSize: 10
					},
					{
						text: `Data: ${moment(this.date).format("DD/MM/yyyy")}`,
						fontSize: 10
					},
					{
						text: "Pregătire pentru lucru - produsele tratate termic (PCC 3)",
						alignment: "center",
						bold: true,
						fontSize: 15,
						margin: [0, 20, 0, 0]
					},
					{
						margin: [0, 20, 0, 0],
						table: this.generateIngredientFryingTable(report)
					},
					{
						text: "Verificat: Dealer/ Șef de stație",
						fontSize: 10,
						alignment: "left",
						margin: [0, 20, 0, 0]
					},
					{
						text: "Semnătura",
						fontSize: 10,
						alignment: "right",
						margin: [0, -11, 50, 0]
					},
					{
						text: "* Temperatura in centrul termic al produsului trebuie sa fie minim 72º C, mentinuta timp de maxim 3 minute.",
						fontSize: 10,
						margin: [0, 30, 0, 0]
					},
					{
						text: "Daca temperatura din centrul termic nu atinge 72º C, sau atinge temperatura dar nu este mentinuta timp de 3 minute, se continua tratamentul termic pentru inca maxim 10 minute",
						fontSize: 10
					},
					{
						text: "Daca dupa aceasta operatiune nu se atinge temperatura de min.72º C, sau nu se poate mentine la 72º C timp de 3 minute, produsul se declarara neconform si se tratateaza ca atare.",
						fontSize: 10
					}
				],
				footer: function (currentPage: number, pageCount: number) {
					return {
						text: `${currentPage.toString()}/${pageCount.toString()}`,
						alignment: "center",
						margin: [0, 10, 0, 0]
					};
				}
			};

			const pdfDocGenerator = pdfMake.createPdf(docDefinition);
			pdfDocGenerator.getDataUrl((dataUrl) => {
				this.iframeURL = dataUrl;
			});
		}
	}
});


import Vue from "vue";
import store from "@/store/index";
import moment from "moment-timezone";
import _ from "lodash";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { EMPTY_OIL_STATS, IOilStats, IOilStatsFilter } from "@common/oil";
import { IOilStatsEntry, IOilStatsReport } from "@common/reports";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import { IStation } from "@common/station";
import { TDocumentDefinitions } from "pdfmake/interfaces";

export default Vue.extend({
	name: "ProductionReport",
	data: () => {
		return {
			date: "",
			iframeURL: "",
			gasStation: {} as IStation,
			gasStations: [] as IStation[],
		};
	},
	created: async function () {
		// Set pdfmake fonts
		pdfMake.vfs = pdfFonts.pdfMake.vfs;

		this.date = this.currentDate;
		try {
			const options: AxiosRequestConfig = {
				method: "GET",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				},
				url: `${store.getters.serverURL}/station/all`,
			};
			const res: AxiosResponse<IServerRES<IStation[]>> = await axios(options);
			if (res.data.err === ServerError.NO_ERROR) {
				this.gasStations = res.data.payload.map((item) => {
					item.name = `(${item.id}) ${item.name}`;

					return item;
				});
				if (this.gasStations.length) {
					this.gasStation = this.gasStations[0];
				}
			}
		} catch (err) {
			console.error(err);
		}
	},
	computed: {
		currentDate () {
			return moment().format("yyyy-MM-DD");
		}
	},
	methods: {
		async fetchData (): Promise<IOilStats[]> {
			try {
				const data: IOilStatsFilter = {
					date: {
						from: moment(this.date).startOf("month").utc().toDate(),
						until: moment(this.date).endOf("month").utc().toDate()
					}
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${this.store.getters.serverURL}/oil/stats/${this.gasStation.id}`,
				};
				const res: AxiosResponse<IServerRES<IOilStats[]>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					const { payload } = res.data;

					return payload;
				}
			} catch (err) {
				console.error(err);
			}

			return [];
		},
		async generateReportData () {
			const oilStats = await this.fetchData();
			const groupedOilStats = _.groupBy(oilStats, x => moment.tz(x.date, "Europe/Bucharest").format("D"));

			const report: IOilStatsReport = [];

			const startDate = moment.tz(this.date, "Europe/Bucharest").startOf("month");
			const endDate = moment.tz(this.date, "Europe/Bucharest").endOf("month");

			for (let currDate = startDate; currDate.isSameOrBefore(endDate); currDate.add(1, "d")) {
				const dayOfTheMonth = currDate.format("D");

				if (groupedOilStats[dayOfTheMonth]) {
					const reportEntry: IOilStatsEntry = {
						dayOfTheMonth,
						tray1: EMPTY_OIL_STATS,
						tray2: EMPTY_OIL_STATS
					};

					const groupedTrays = _.groupBy(groupedOilStats[dayOfTheMonth], x => x.tray);

					reportEntry.tray1 = groupedTrays["1"] ? groupedTrays["1"][0] : EMPTY_OIL_STATS;
					reportEntry.tray2 = groupedTrays["2"] ? groupedTrays["2"][0] : EMPTY_OIL_STATS;

					report.push(reportEntry);
				} else {
					report.push({
						dayOfTheMonth,
						tray1: EMPTY_OIL_STATS,
						tray2: EMPTY_OIL_STATS
					});
				}
			}

			return report;
		},
		generateTable (report: IOilStatsReport) {
			const table = {
				headerRows: 2,
				widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
				body: [
					[
						{
							text: "Data din luna curentă",
							alignment: "center",
							rowSpan: 2
						},
						{
							text: "Parametrii fizici ai uleiului",
							alignment: "center",
							colSpan: 2
						},
						{},
						{
							text: "Verificarea uleiului cu tester",
							alignment: "center",
							colSpan: 2
						},
						{},
						{
							text: "Parametrii chimici ai uleiului",
							alignment: "center",
							colSpan: 2
						},
						{},
						{
							text: "Măsura intreprinsa (turnare, schimbare)",
							alignment: "center",
							colSpan: 2
						},
						{},
						{
							text: "Responsabil executie",
							alignment: "center",
							colSpan: 2
						},
						{}
					],
					[
						{},
						{
							text: "Cuva 1",
							alignment: "center",
						},
						{
							text: "Cuva 2",
							alignment: "center",
						},
						{
							text: "Cuva 1",
							alignment: "center",
						},
						{
							text: "Cuva 2",
							alignment: "center",
						},
						{
							text: "Cuva 1",
							alignment: "center",
						},
						{
							text: "Cuva 2",
							alignment: "center",
						},
						{
							text: "Cuva 1",
							alignment: "center",
						},
						{
							text: "Cuva 2",
							alignment: "center",
						},
						{
							text: "Cuva 1",
							alignment: "center",
						},
						{
							text: "Cuva 2",
							alignment: "center",
						}
					]
				]
			};

			for (const entry of report) {
				table.body.push([
					{
						text: entry.dayOfTheMonth,
						alignment: "center"
					},
					{
						text: entry.tray1 !== EMPTY_OIL_STATS ? entry.tray1.status.toString() : "-",
						alignment: "center"
					},
					{
						text: entry.tray2 !== EMPTY_OIL_STATS ? entry.tray2.status.toString() : "-",
						alignment: "center"
					},
					{
						text: entry.tray1 !== EMPTY_OIL_STATS ? (entry.tray1.testerCheck ? "Da" : "Nu") : "-",
						alignment: "center"
					},
					{
						text: entry.tray2 !== EMPTY_OIL_STATS ? (entry.tray2.testerCheck ? "Da" : "Nu") : "-",
						alignment: "center"
					},
					{
						text: entry.tray1 !== EMPTY_OIL_STATS ? entry.tray1.chimicalParameters.toString() : "-",
						alignment: "center"
					},
					{
						text: entry.tray2 !== EMPTY_OIL_STATS ? entry.tray2.chimicalParameters.toString() : "-",
						alignment: "center"
					},
					{
						text: entry.tray1 !== EMPTY_OIL_STATS ? entry.tray1.operation : "-",
						alignment: "center"
					},
					{
						text: entry.tray2 !== EMPTY_OIL_STATS ? entry.tray2.operation : "-",
						alignment: "center"
					},
					{
						text: entry.tray1 !== EMPTY_OIL_STATS ? (`${entry.tray1.operatorName}\n\n\n` || "Necunoscut") : "-",
						alignment: "center"
					},
					{
						text: entry.tray2 !== EMPTY_OIL_STATS ? (`${entry.tray2.operatorName}\n\n\n` || "Necunoscut") : "-",
						alignment: "center"
					}
				]);
			}

			return table;
		},
		async generatePDF () {
			const report = await this.generateReportData();

			const docDefinition: TDocumentDefinitions = {
				pageOrientation: "landscape",
				content: [
					{
						text: `STAȚIA: ${this.gasStation.name.replace(`(${this.gasStation.id})`, "").trim()}`,
						fontSize: 10
					},
					{
						text: "V1_2021",
						alignment: "right",
						fontSize: 10
					},
					{
						text: `Data: ${moment(this.date).format("MM/yyyy")}`,
						fontSize: 10
					},
					{
						text: "Lista de verificare a valabilităţii uleiului de prăjire",
						alignment: "center",
						bold: true,
						fontSize: 15,
						margin: [0, 20, 0, 0]
					},
					{
						margin: [0, 20, 0, 0],
						table: this.generateTable(report)
					},
					{
						text: "Verificat: Dealer/ Șef de stație",
						fontSize: 10,
						alignment: "left",
						margin: [0, 20, 0, 0],
					},
					{
						text: "Semnătura",
						fontSize: 10,
						alignment: "right",
						margin: [0, -11, 50, 0]
					},
					{
						text: "NOTA (*): La verificarea aspectului uleiului se va scrie:",
						fontSize: 10,
						alignment: "left",
						margin: [0, 30, 0, 0],
					},
					{
						text: "1. aspect limpede",
						fontSize: 10,
						alignment: "left",
						margin: [15, 0, 0, 0]
					},
					{
						text: "2. uşor tulbure",
						fontSize: 10,
						alignment: "left",
						margin: [15, 0, 0, 0]
					},
					{
						text: "3. modificare de culoare în roşcat (sau maroniu)",
						fontSize: 10,
						alignment: "left",
						margin: [15, 0, 0, 0]
					},
					{
						text: "Schimbarea uleiului din cuva se realizeaza atunci cand 2 din dungile testerului de ulei isi modifica culoarea complet din albastru in galben, conform Instructiunilor privind verificarea uleiului cu Testere Ulei 3M.",
						fontSize: 10,
						alignment: "left"
					},
					{
						text: "CUVA 1 = Cuva din stanga (pentru produse din carne)   CUVA 2 = Cuva din dreapta (pentru cartofi)",
						fontSize: 10,
						alignment: "left",
						bold: true
					}
				],
				footer: function (currentPage: number, pageCount: number) {
					return {
						text: `${currentPage.toString()}/${pageCount.toString()}`,
						alignment: "center",
						margin: [0, 10, 0, 0]
					};
				}
			};

			const pdfDocGenerator = pdfMake.createPdf(docDefinition);
			pdfDocGenerator.getDataUrl((dataUrl) => {
				this.iframeURL = dataUrl;
			});
		}
	}
});

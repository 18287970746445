export interface IOilStats {
    stationId: string;
    tray: number;
    status: number;
    testerCheck: boolean;
    chimicalParameters: number;
    operation: string;
    operatorId: string;
    operatorName?: string;
    date: Date;
}

export const EMPTY_OIL_STATS: IOilStats = {
    stationId: "",
    tray: -1,
    status: -1,
    testerCheck: false,
    chimicalParameters: -1,
    operation: "",
    operatorId: "",
    date: new Date()
}

export interface IOilStatsFilter {
    date: {
        from: Date,
        until: Date
    };
}

export function isOilStats (obj: any): boolean {
    const isStationId = obj.stationId !== undefined && obj.stationId !== null;
    const isTray = obj.tray !== undefined && obj.tray !== null;
    const isStatus = obj.status !== undefined && obj.status !== null;
    const isTesterCheck = obj.testerCheck !== undefined && obj.testerCheck !== null;
    const isChimicalParameters = obj.chimicalParameters !== undefined && obj.chimicalParameters !== null;
    const isOperation = obj.operation !== undefined && obj.operation !== null;
    const isOperatorID = obj.operatorId !== undefined && obj.operatorId !== null;
    const isDate = obj.date !== undefined && obj.date !== null;
    
    

    const ret = isStationId && isTray && isStatus && isTesterCheck && isChimicalParameters
                    && isOperation && isOperatorID && isDate;

    return ret;
}

import Vue from "vue";

import ProductionHAACPReportVue from "@/components/haacp/ProductionHAACPReport.vue";
import OilHAACPReportVue from "@/components/haacp/OilHAACPReport.vue";
import BreadHAACPReportVue from "@/components/haacp/BreadHAACPReport.vue";
import IngredientFryingHAACPReportVue from "@/components/haacp/IngredientFryingHAACPReport.vue";

export default Vue.extend({
	name: "HAACP",
	components: {
		"production-haacp-report": ProductionHAACPReportVue,
		"oil-haacp-report": OilHAACPReportVue,
		"bread-haacp-report": BreadHAACPReportVue,
		"ingredient-frying-haacp-report": IngredientFryingHAACPReportVue
	},
	data: () => {
		return {
			haacpReports: [
				{
					text: "Production HAACP Report",
					value: "production-haacp-report"
				},
				{
					text: "Oil HAACP Report",
					value: "oil-haacp-report"
				},
				{
					text: "Bread HAACP Report",
					value: "bread-haacp-report"
				},
				{
					text: "Bread HAACP Report",
					value: "bread-haacp-report"
				},
				{
					text: "Ingredient Frying HAACP Report",
					value: "ingredient-frying-haacp-report"
				}
			],
			report: {}
		};
	},
	created: async function () {
		this.store.dispatch.changeAppTitle("HAACP");
		if (this.haacpReports.length) {
			this.report = this.haacpReports[0];
		}
	}
});

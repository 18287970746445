
import Vue from "vue";
import _ from "lodash";
import moment from "moment-timezone";
import store from "@/store/index";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import { IStation } from "@common/station";
import { IDemandRES } from "@common/demand";

// import projectedDemand from "@/data/projected_demand.json";
import ProjectedDemandSupplyChart from "@/components/demand/ProjectedDemandSupplyChart.vue";
import ProjectedTimesChart from "@/components/demand/ProjectedTimesChart.vue";

interface DemandResponse extends Response {
	err?: ServerError;
	RESULTS: {}[];
}

export default Vue.extend({
	name: "ProjectedDemand",
	components: {
		"projected-demand-supply-chart": ProjectedDemandSupplyChart,
		"projected-times-chart": ProjectedTimesChart,
	},
	data: () => {
		const ret: {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			projectedDemand: any;
			selectedSw: { name: string };
			availableSw: { name: string }[];
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			chartData: any;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			demandSupplyShow: any;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			timesShow: any;
			accuracy: number;
			gasStations: IStation[];
			allowedDates: IDemandRES[];
			availableHours: string[];
			filters: {
				gasStation: IStation;
				datePicker: string;
				selectedTime: string;
				dateRange: string[];
			};
			menus: {
				dateSelect: boolean;
				timeSelect: boolean;
			};
			xlsxAvailable: boolean;
		} = {
			projectedDemand: [],
			selectedSw: {
				name: "Total",
			},
			availableSw: [],
			chartData: {
				projectedDemandSupplyChart: null,
				projectedTimesChart: null,
			},
			demandSupplyShow: null,
			timesShow: null,
			accuracy: 0,
			gasStations: [] as IStation[],
			allowedDates: [] as IDemandRES[],
			availableHours: [] as string[],
			filters: {
				gasStation: {} as IStation,
				datePicker: moment().format("YYYY-MM-DD"),
				selectedTime: "09:00",
				dateRange: [
					moment().subtract(29, "days").format("YYYY-MM-DD"),
					moment().format("YYYY-MM-DD")
				],
			},
			menus: {
				dateSelect: false,
				timeSelect: false
			},
			xlsxAvailable: false,
		};
		return ret;
	},
	methods: {
		async checkProjectedXlsxExists () {
			this.xlsxAvailable = false;

			try {
				const options: AxiosRequestConfig = {
					method: "GET",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/demand/${this.filters.gasStation.id}/${moment(this.filters.datePicker).format("YYYY/MM/DD")}/${this.filters.selectedTime.split(":")[0]}/xlsx/exists`,
				};				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const res: AxiosResponse<IServerRES<any>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					this.xlsxAvailable = true;
				}
			} catch (err) {
				console.error(err);
			}
		},
		isAllowedDate (date: string): boolean {
			return this.allowedDates.find((d) => d.date === date) !== undefined;
		},
		b64toBlob (b64Data: string, contentType = "", sliceSize = 512) {
			const byteCharacters = atob(b64Data);
			const byteArrays = [];

			for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
				const slice = byteCharacters.slice(offset, offset + sliceSize);
				const byteNumbers = new Array(slice.length);

				for (let i = 0; i < slice.length; i++) {
					byteNumbers[i] = slice.charCodeAt(i);
				}

				const byteArray = new Uint8Array(byteNumbers);
				byteArrays.push(byteArray);
			}

			const blob = new Blob(byteArrays, { type: contentType });
			return blob;
		},
		async getProjectedXlsx () {
			try {
				const options: AxiosRequestConfig = {
					method: "GET",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/demand/${this.filters.gasStation.id}/${moment(this.filters.datePicker).format("YYYY/MM/DD")}/${this.filters.selectedTime.split(":")[0]}/xlsx`,
				};				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const res: AxiosResponse<IServerRES<any>> = await axios(options);
				if (res.data.err === undefined) {
					const file = this.b64toBlob(res.data);
					const fileURL = URL.createObjectURL(file);
					const a = document.createElement("a");

					a.href = fileURL;
					a.download = `ProjectedDemand_${this.filters.gasStation.id}_${moment(this.filters.datePicker).format("YYYY-MM-DD")}_${this.filters.selectedTime.split(":")[0]}.xlsx`;
					document.body.appendChild(a);

					a.click();
				}
			} catch (err) {
				console.error(err);
			}
		},
		async getDemandData (): Promise<void> {
			if (this.projectedDemand.length > 0) {
				this.projectedDemand = [];
			}
			try {
				const options: AxiosRequestConfig = {
					method: "GET",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/demand/${this.filters.gasStation.id}/${moment(this.filters.datePicker).format("YYYY/MM/DD")}/${this.filters.selectedTime.split(":")[0]}`,
				};
				const res: AxiosResponse<DemandResponse> = await axios(options);
				if (res.data.err === undefined) {
					if (res.data.RESULTS !== undefined && res.data.RESULTS !== null) {
						this.projectedDemand = Object.values(res.data.RESULTS);
					}
				}
			} catch (err) {
				console.error(err);
			}
			this.changeSw(this.selectedSw);
		},
		changeSw: function (selectedSw: { name: string }) {
			const parsed = this.removeHoles(this.projectedDemand);
			this.chartData.projectedDemandSupplyChart = {
				labels: _.map(parsed, (o) => o.hour),
				datasets: [
					{
						label: "Demand",
						data: _.map(
							parsed,
							(o) => o.SWS_DEMAND[selectedSw.name],
						),
						borderColor: "#EF9234",
						backgroundColor: "#EF9234",
						borderDash: [3, 5],
					},
					{
						label: "Supply",
						data: _.map(
							parsed,
							(o) => o.SWS_SUPPLY[selectedSw.name],
						),
						borderColor: "#7CC674",
						backgroundColor: "#7CC674",
					},
					{
						type: "bar",
						label: "Day separator",
						data: _.map(parsed, (o) =>
							o.SWS_SUPPLY[selectedSw.name] === "x"
								? _.max(
									_.map(
										parsed,
										(o) =>
											o.SWS_DEMAND[selectedSw.name],
									).concat(
										_.map(
											parsed,
											(o) =>
												o.SWS_SUPPLY[
													selectedSw.name
												],
										),
									),
								)
								: 0,
						),
						fill: false,
						tooltip: false,
					},
				],
			};
			if (this.$refs.projectedDemandSupplyChart) {
				(
					this.$refs.projectedDemandSupplyChart as InstanceType<
						typeof ProjectedDemandSupplyChart
					>
				).updateChart();
			}

			const productData = _.map(
				parsed,
				(o) => o.SWS_TIMES.Total,
			);
			const ingredientsData = _.map(parsed, (o) =>
				// _.sum(Object.values(o.ING_TIMES)),
				o.ING_TIMES.Total,
			);

			this.chartData.projectedTimesChart = {
				labels: _.map(parsed, (o) => o.hour),
				datasets: [
					{
						label: "Product",
						data: productData,
						borderColor: "#EF9234",
						backgroundColor: "#EF9234",
						borderDash: [3, 5],
					},
					{
						label: "Ingredients",
						data: ingredientsData,
						borderColor: "#7CC674",
						backgroundColor: "#7CC674",
					},
					{
						label: "Free",
						data: _.map(parsed, (o, index) =>
							o.SWS_SUPPLY.Total === "x"
								? 0
								: o.TOTAL_AVAILABLE_TIME -
									productData[index] -
									ingredientsData[index],
						),
						borderColor: "#B2BEB5",
						backgroundColor: "#B2BEB5",
					},
					{
						type: "bar",
						label: "Line Dataset",
						data: _.map(parsed, (o) =>
							o.SWS_SUPPLY.Total === "x" ? o.TOTAL_AVAILABLE_TIME : 0,
						),
						fill: false,
					},
				],
			};
			if (this.$refs.projectedTimesChart) {
				(
					this.$refs.projectedTimesChart as InstanceType<
						typeof ProjectedTimesChart
					>
				).updateChart();
			}

			const parsedForAccuracy = _.filter(
				parsed,
				(o) => o.SWS_SUPPLY[selectedSw.name] !== "x",
			);

			const demandData = _.map(
				parsedForAccuracy,
				(o) => o.SWS_DEMAND[selectedSw.name],
			);
			const supplyData = _.map(
				parsedForAccuracy,
				(o) => o.SWS_SUPPLY[selectedSw.name],
			);

			this.accuracy =
				Math.round(
					(1 -
						_.sum(
							_.map(demandData, (d, index) => {
								return Math.abs(d - supplyData[index]);
							}),
						) /
							_.sum(demandData)) *
						10000,
				) / 100;
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		removeHoles: function (arr: any[]) {
			const final = [];
			let hole = false;
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			let lastElement = null;
			for (let index = 0; index < arr.length; index++) {
				const element = arr[index];
				if (element.SWS_DEMAND["Sunca Pita (CUMULAT)"] !== "x") {
					// if (lastElement) {
					// 	final.push(lastElement);
					// 	lastElement = null;
					// }
					final.push(element);
					hole = false;
					lastElement = element;
				} else {
					lastElement = element;
					if (hole) {
						lastElement = element;
					} else {
						final.push(element);
						hole = true;
					}
				}
			}
			return final;
		},
		clickedChart1: function (index: number) {
			const parsed = this.removeHoles(this.projectedDemand);
			this.demandSupplyShow = parsed[index];
		},
		clickedChart2: function (index: number) {
			const parsed = this.removeHoles(this.projectedDemand);
			this.timesShow = parsed[index];
		},
	},
	created: async function () {
		try {
			const options: AxiosRequestConfig = {
				method: "GET",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				},
				url: `${store.getters.serverURL}/station/all`,
			};
			const res: AxiosResponse<IServerRES<IStation[]>> = await axios(options);
			if (res.data.err === ServerError.NO_ERROR) {
				this.gasStations = res.data.payload.map((item) => {
					item.name = `(${item.id}) ${item.name}`;

					return item;
				});
				if (this.gasStations.length) {
					this.filters.gasStation = this.gasStations[0];
				}
			}
		} catch (err) {
			console.error(err);
		}
		this.store.dispatch.changeAppTitle("Projected Demand");
	},
	watch: {
		projectedDemand: {
			handler: function () {
				if (this.projectedDemand.length > 0) {
					for (const sw of Object.keys(this.projectedDemand[0].SWS_DEMAND)) {
						this.availableSw.push({
							name: sw
						});
					}
					this.selectedSw = this.availableSw[0];
					if (this.availableSw.length) {
						this.selectedSw = this.availableSw[0];
					}
				}
			},
			deep: true,
		},
		selectedSw: function (selectedSw) {
			this.demandSupplyShow = null;
			this.timesShow = null;
			this.changeSw(selectedSw);
		},
		"filters.gasStation": async function () {
			try {
				const options: AxiosRequestConfig = {
					method: "GET",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/demand/available/${this.filters.gasStation.id}/`,
				};
				const res: AxiosResponse<IServerRES<IDemandRES[]>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					this.allowedDates = res.data.payload;

					const dates = this.allowedDates.find(
						(o) => o.date === this.filters.datePicker,
					);
					if (dates) {
						this.availableHours = dates.hours.map((item: number) => {
							return String(item).padStart(2, "0") + ":00";
						});
						this.filters.selectedTime = this.availableHours[0];
					}
					this.getDemandData();
				}
			} catch (err) {
				console.error(err);
			}
		},
		"filters.datePicker": function () {
			const dates = this.allowedDates.find(
				(o) => o.date === this.filters.datePicker,
			);
			if (dates) {
				this.availableHours = dates.hours.map((item: number) => {
					return String(item).padStart(2, "0") + ":00";
				});
				this.filters.selectedTime = this.availableHours[0];
			}
		},
		"filters.selectedTime": function () {
			this.checkProjectedXlsxExists();
		},
		filters: {
			deep: true,
			handler: function () {
				this.getDemandData();
			},
		},
	},
});

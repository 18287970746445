
import Vue from "vue";
import store from "@/store/index";
import moment from "moment-timezone";
import _ from "lodash";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IProductProduction, IProductProductionFilter } from "@common/product";
import { IProductionReport, IProductionReportEntry } from "@common/reports";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import { IStation } from "@common/station";
import { TDocumentDefinitions } from "pdfmake/interfaces";

export default Vue.extend({
	name: "ProductionReport",
	data: () => {
		return {
			date: "",
			iframeURL: "",
			gasStation: {} as IStation,
			gasStations: [] as IStation[],
		};
	},
	created: async function () {
		// Set pdfmake fonts
		pdfMake.vfs = pdfFonts.pdfMake.vfs;

		this.date = this.currentDate;
		try {
			const options: AxiosRequestConfig = {
				method: "GET",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				},
				url: `${store.getters.serverURL}/station/all`,
			};
			const res: AxiosResponse<IServerRES<IStation[]>> = await axios(options);
			if (res.data.err === ServerError.NO_ERROR) {
				this.gasStations = res.data.payload.map((item) => {
					item.name = `(${item.id}) ${item.name}`;

					return item;
				});
				if (this.gasStations.length) {
					this.gasStation = this.gasStations[0];
				}
			}
		} catch (err) {
			console.error(err);
		}
	},
	computed: {
		currentDate () {
			return moment().format("yyyy-MM-DD");
		}
	},
	methods: {
		async fetchData (): Promise<IProductProduction[]> {
			try {
				const data: IProductProductionFilter = {
					date: {
						from: moment(this.date).startOf("day").utc().toDate(),
						until: moment(this.date).endOf("day").utc().toDate()
					}
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${this.store.getters.serverURL}/product/production/${this.gasStation.id}`,
				};
				const res: AxiosResponse<IServerRES<IProductProduction[]>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					const { payload } = res.data;

					return payload;
				}
			} catch (err) {
				console.error(err);
			}

			return [];
		},
		async generateReportData () {
			const productStats = await this.fetchData();
			const groupedProducts = _.groupBy(productStats, "productId");
			const report: IProductionReport = [];
			for (const key in groupedProducts) {
				if (groupedProducts[key].length) {
					const tmpReport: IProductionReport = [];
					for (const item of groupedProducts[key]) {
						if (item.productTitle && item.productionOperatorName) {
							const entry: IProductionReportEntry = {
								title: item.productTitle,
								productionTime: moment(item.productionDate).toDate(),
								lotNr: moment.tz(item.productionDate, "Europe/Bucharest").format("DDMM / HHmm"),
								qty: 1,
								responsible: item.productionOperatorName
							};
							tmpReport.push(entry);
						}
					}
					const reportEntriesGrouped = _.groupBy(tmpReport, "lotNr");
					for (const lotNr in reportEntriesGrouped) {
						if (reportEntriesGrouped[lotNr].length) {
							const entry: IProductionReportEntry = {
								title: reportEntriesGrouped[lotNr][0].title,
								productionTime: reportEntriesGrouped[lotNr][0].productionTime,
								lotNr: lotNr,
								qty: reportEntriesGrouped[lotNr].length,
								responsible: reportEntriesGrouped[lotNr][0].responsible
							};
							report.push(entry);
						}
					}
				}
			}

			report.sort((a, b) => moment(a.productionTime).diff(moment(b.productionTime)));

			return report;
		},
		generateTable (report: IProductionReport) {
			const table = {
				headerRows: 1,
				widths: [150, "auto", "auto", "auto", 100],
				body: [
					[
						{
							text: "Denumire produs",
							alignment: "center"
						},
						{
							text: "Cantitate produsă\n(buc)",
							alignment: "center"

						},
						{
							text: "Ora producției (ora montării)",
							alignment: "center"
						},
						{
							text: "Nr. Lot producție*",
							alignment: "center"
						},
						{
							text: "Responsabil producție",
							alignment: "center"
						}
					]
				]
			};

			for (const entry of report) {
				table.body.push([
					{
						text: entry.title,
						alignment: "center"
					},
					{
						text: entry.qty.toString(),
						alignment: "center"
					},
					{
						text: moment(entry.productionTime).format("HH:mm"),
						alignment: "center"
					},
					{
						text: entry.lotNr,
						alignment: "center"
					},
					{
						text: entry.responsible,
						alignment: "center"
					}
				]);
			}

			return table;
		},
		async generatePDF () {
			const report = await this.generateReportData();

			const docDefinition: TDocumentDefinitions = {
				content: [
					{
						text: `STAȚIA: ${this.gasStation.name.replace(`(${this.gasStation.id})`, "").trim()}`,
						fontSize: 10
					},
					{
						text: "V1_2021",
						alignment: "right",
						fontSize: 10
					},
					{
						text: `Data: ${moment(this.date).format("DD/MM/yyyy")}`,
						fontSize: 10
					},
					{
						text: "Lista de producție sandwichuri",
						alignment: "center",
						bold: true,
						fontSize: 15,
						margin: [0, 20, 0, 0]
					},
					{
						margin: [0, 20, 0, 0],
						table: this.generateTable(report)
					},
					{
						text: "Verificat: Dealer/ Șef de stație",
						fontSize: 10,
						alignment: "left",
						margin: [0, 20, 0, 0],
					},
					{
						text: "Semnătura",
						fontSize: 10,
						alignment: "right",
						margin: [0, -11, 50, 0]
					},
				],
				footer: function (currentPage: number, pageCount: number) {
					return {
						text: `${currentPage.toString()}/${pageCount.toString()}`,
						alignment: "center",
						margin: [0, 10, 0, 0]
					};
				}
			};

			const pdfDocGenerator = pdfMake.createPdf(docDefinition);
			pdfDocGenerator.getDataUrl((dataUrl) => {
				this.iframeURL = dataUrl;
			});
		}
	}
});

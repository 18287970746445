
import Vue from "vue";
import store from "@/store/index";
import moment from "moment-timezone";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { EMPTY_BREAD_STATS, IBreadStats, IBreadStatsFilter } from "@common/bread";
import {
	IBreadBakingReport,
	IBreadBakingReportEntry,
	IBreadDefrostReport,
	IBreadDefrostReportEntry,
	IBreadReport
} from "@common/reports";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import { IStation } from "@common/station";
import { TDocumentDefinitions } from "pdfmake/interfaces";

export default Vue.extend({
	name: "BreadReport",
	data: () => {
		return {
			date: "",
			iframeURL: "",
			gasStation: {} as IStation,
			gasStations: [] as IStation[],
		};
	},
	created: async function () {
		// Set pdfmake fonts
		pdfMake.vfs = pdfFonts.pdfMake.vfs;

		this.date = this.currentDate;
		try {
			const options: AxiosRequestConfig = {
				method: "GET",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				},
				url: `${store.getters.serverURL}/station/all`,
			};
			const res: AxiosResponse<IServerRES<IStation[]>> = await axios(options);
			if (res.data.err === ServerError.NO_ERROR) {
				this.gasStations = res.data.payload.map((item) => {
					item.name = `(${item.id}) ${item.name}`;

					return item;
				});
				if (this.gasStations.length) {
					this.gasStation = this.gasStations[0];
				}
			}
		} catch (err) {
			console.error(err);
		}
	},
	computed: {
		currentDate () {
			return moment().format("yyyy-MM-DD");
		}
	},
	methods: {
		async fetchData (): Promise<IBreadStats> {
			try {
				const data: IBreadStatsFilter = {
					date: {
						from: moment(this.date).startOf("day").utc().toDate(),
						until: moment(this.date).endOf("day").utc().toDate()
					}
				};
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					data,
					url: `${this.store.getters.serverURL}/bread/stats/${this.gasStation.id}`,
				};
				const res: AxiosResponse<IServerRES<IBreadStats>> = await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					const { payload } = res.data;

					return payload;
				}
			} catch (err) {
				console.error(err);
			}

			return EMPTY_BREAD_STATS;
		},
		async generateReportData (): Promise<IBreadReport> {
			const breadStats = await this.fetchData();
			const report: IBreadReport = {
				baking: [],
				defrost: []
			};

			for (const item of breadStats.baking) {
				const entry: IBreadBakingReportEntry = {
					ingredient: item.ingredientName || "-",
					valabilityAfterBaking: item.ingredientValabilityAfterBaking ? item.ingredientValabilityAfterBaking : "-",
					operatorName: item.operatorName || "-",
					qty: item.qty,
					date: item.date,
					batch: item.batch
				};

				report.baking.push(entry);
			}

			for (const item of breadStats.defrost) {
				const entry: IBreadDefrostReportEntry = {
					ingredient: item.ingredientName || "-",
					valabilityAfterDefrost: item.ingredientValabilityAfterDefrost ? item.ingredientValabilityAfterDefrost : "-",
					operatorName: item.operatorName || "-",
					qty: item.qty,
					dateStart: item.dateStart,
					dateEnd: item.dateEnd,
					batch: item.batch
				};

				report.defrost.push(entry);
			}

			return report;
		},
		generateBreadBakingTable (bakingReport: IBreadBakingReport) {
			const table = {
				headerRows: 1,
				widths: [150, "auto", "auto", "auto", "auto", "auto"],
				body: [
					[
						{
							text: "Denumire produs",
							alignment: "center"
						},
						{
							text: "Cantitate produsă",
							alignment: "center"

						},
						{
							text: "Ora producției (ora scoaterii din cuptor)",
							alignment: "center"
						},
						{
							text: "Nr. Lot furnizor",
							alignment: "center"
						},
						{
							text: "Termen de valabilitate al produsului dupa coacere",
							alignment: "center"
						},
						{
							text: "Responsabil producție",
							alignment: "center"
						}
					]
				]
			};

			for (const entry of bakingReport) {
				table.body.push([
					{
						text: entry.ingredient,
						alignment: "center"
					},
					{
						text: entry.qty.toString(),
						alignment: "center"
					},
					{
						text: moment(entry.date).format("HH:mm"),
						alignment: "center"
					},
					{
						text: entry.batch,
						alignment: "center"
					},
					{
						text: entry.valabilityAfterBaking,
						alignment: "center"
					},
					{
						text: entry.operatorName,
						alignment: "center"
					}
				]);
			}

			return table;
		},
		generateBreadDefrostTable (defrostReport: IBreadDefrostReport) {
			const table = {
				headerRows: 1,
				widths: [100, "auto", "auto", "auto", "auto", "auto", "auto"],
				body: [
					[
						{
							text: "Denumire produs",
							alignment: "center"
						},
						{
							text: "Nr. bucăți scoase la decongelat",
							alignment: "center"

						},
						{
							text: "Ora scoaterii la decongelat",
							alignment: "center"
						},
						{
							text: "Ora decongelării",
							alignment: "center"
						},
						{
							text: " Lot furnizor",
							alignment: "center"
						},
						{
							text: "Termen valabilitate din momentul decongelării",
							alignment: "center"
						},
						{
							text: "Responsabil producție",
							alignment: "center"
						}
					]
				]
			};

			for (const entry of defrostReport) {
				table.body.push([
					{
						text: entry.ingredient,
						alignment: "center"
					},
					{
						text: entry.qty.toString(),
						alignment: "center"
					},
					{
						text: moment(entry.dateStart).format("HH:mm"),
						alignment: "center"
					},
					{
						text: moment(entry.dateEnd).format("HH:mm"),
						alignment: "center"
					},
					{
						text: entry.batch,
						alignment: "center"
					},
					{
						text: entry.valabilityAfterDefrost,
						alignment: "center"
					},
					{
						text: entry.operatorName,
						alignment: "center"
					}
				]);
			}

			return table;
		},
		async generatePDF () {
			const report = await this.generateReportData();

			const docDefinition: TDocumentDefinitions = {
				content: [
					{
						text: `STAȚIA: ${this.gasStation.name.replace(`(${this.gasStation.id})`, "").trim()}`,
						fontSize: 10
					},
					{
						text: "V1_2021",
						alignment: "right",
						fontSize: 10
					},
					{
						text: `Data: ${moment(this.date).format("DD/MM/yyyy")}`,
						fontSize: 10
					},
					{
						text: "Lista de producție pâine (coacere și decongelare)",
						alignment: "center",
						bold: true,
						fontSize: 15,
						margin: [0, 20, 0, 0]
					},
					{
						margin: [0, 20, 0, 0],
						table: this.generateBreadBakingTable(report.baking)
					},
					{
						margin: [0, 20, 0, 0],
						table: this.generateBreadDefrostTable(report.defrost)
					},
					{
						text: "Verificat: Dealer/ Șef de stație",
						fontSize: 10,
						alignment: "left",
						margin: [0, 20, 0, 0],
					},
					{
						text: "Semnătura",
						fontSize: 10,
						alignment: "right",
						margin: [0, -11, 50, 0]
					},
				],
				footer: function (currentPage: number, pageCount: number) {
					return {
						text: `${currentPage.toString()}/${pageCount.toString()}`,
						alignment: "center",
						margin: [0, 10, 0, 0]
					};
				}
			};

			const pdfDocGenerator = pdfMake.createPdf(docDefinition);
			pdfDocGenerator.getDataUrl((dataUrl) => {
				this.iframeURL = dataUrl;
			});
		}
	}
});

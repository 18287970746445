
import Vue from "vue";
import {
	Chart,
	ChartData,
	DoughnutController,
	PieController,
	ArcElement,
	CategoryScale,
	LinearScale,
	Title,
	Tooltip
} from "chart.js";

Chart.register(
	PieController,
	DoughnutController,
	ArcElement,
	CategoryScale,
	LinearScale,
	Title,
	Tooltip
);

export default Vue.extend({
	name: "ProjectedTimesChart",
	props: {
		chartId: String,
		stationId: String,
		chartDataProp: Object,
		clickedChart: Function
	},
	data: () => {
		return {
			chart: null as Chart | null,
			chartData: {} as ChartData,
			chartTitle: "Projected Times"
		};
	},
	created: function () {
		this.chartData = this.$props.chartDataProp;
	},
	mounted: function () {
		this.initChart();
	},
	methods: {
		initChart () {
			const target = document.getElementById(this.$props.chartId) as HTMLCanvasElement;
			if (target !== null) {
				const canvas = target.getContext("2d");
				if (canvas !== null) {
					this.chart = new Chart(canvas, {
						type: "bar",
						data: this.chartData,
						options: {
							plugins: {
								title: {
									display: true,
									text: this.chartTitle
								},
								legend: {
									display: false
								},
								tooltip: {
									// eslint-disable-next-line
									filter: function (item, data) {
										return item.datasetIndex !== 3;
									}
								}
							},
							layout: {
								padding: {
									left: 30,
									right: 30,
									top: 0,
									bottom: 0
								}
							},
							scales: {
								x: {
									stacked: true,
								},
								y: {
									stacked: true,
									title: {
										display: true,
										text: "Time (seconds)"
									}
								}
							}
						},
					});

					target.onclick = (evt) => {
						if (this.chart) {
							const points = this.chart.getElementsAtEventForMode(evt, "nearest", { intersect: true }, true);
							if (points.length) {
								const index = points[0].index;
								if (points[0].datasetIndex !== 3) {
									this.$props.clickedChart(index);
								}
							}
						}
					};
				} else {
					console.error("failed getting context");
				}
			}
		},
		updateChart () {
			this.chartData = this.$props.chartDataProp;
			if (this.chartData) {
				if (this.chart) {
					this.chart.update();
				}
			}
		}
	}
});

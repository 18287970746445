import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"breadReport"},[_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c(VRow,{attrs:{"align":"center","justify":"space-around"}},[_c(VAutocomplete,{attrs:{"label":"Gas Station","items":_vm.gasStations,"item-text":"name","return-object":""},model:{value:(_vm.gasStation),callback:function ($$v) {_vm.gasStation=$$v},expression:"gasStation"}})],1),_c(VRow,{attrs:{"align":"center","justify":"space-around"}},[_c(VDatePicker,{staticClass:"mt-4",attrs:{"max":_vm.currentDate},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c(VRow,{staticClass:"mt-8",attrs:{"align":"center","justify":"space-around"}},[_c(VBtn,{attrs:{"color":"success","large":""},on:{"click":_vm.generatePDF}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("restore_page")]),_vm._v(" Generate Report ")],1)],1)],1),_c(VDivider,{attrs:{"vertical":""}}),_c(VCol,[_c(VRow,{staticClass:"ml-2",attrs:{"align":"center","justify":"space-around"}},[(_vm.iframeURL)?_c('iframe',{attrs:{"src":_vm.iframeURL,"width":"100%","height":"700","frameBorder":"0"}}):_c(VIcon,{attrs:{"size":"200","color":"grey lighten-2","align":"center"}},[_vm._v("description")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
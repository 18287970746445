export interface IBreadStatsFilter {
    date: {
        from: Date,
        until: Date
    };
}
export interface IBreadDefrostStatsFilter extends IBreadStatsFilter {};
export interface IBreadBakingStatsFilter extends IBreadStatsFilter {};

export interface IBreadBakingStats {
    id?: string;
    ingredientId: string;
    ingredientName?: string;
    ingredientValabilityAfterBaking?: string | null;
    stationId: string;
    stationName?: string;
    operatorId: string;
    operatorName?: string;
    qty: number;
    date: Date;
    batch: string;
    barcode: string;
    barcodeIngredientOpen?: string;
    dateExpire?: Date;
}

export interface IBreadDefrostStats {
    id?: string;
    ingredientId: string;
    ingredientName?: string;
    ingredientValabilityAfterDefrost?: string | null;
    stationId: string;
    stationName?: string;
    operatorId: string;
    operatorName?: string;
    qty: number;
    dateStart: Date;
    dateEnd: Date;
    dateExpire?: Date;
    batch: string;
    barcode: string;
    barcodeIngredientOpen?: string;
}

export interface IBreadStats {
    baking: IBreadBakingStats[],
    defrost: IBreadDefrostStats[]
}

export const EMPTY_BREAD_STATS: IBreadStats = {
    baking: [],
    defrost: []
}

export function isBreadBakingStats (obj: any): boolean {
    const isIngredientId = obj.ingredientId !== undefined && obj.ingredientId !== null;
    const isStationId = obj.stationId !== undefined && obj.stationId !== null;
    const isOperatorID = obj.operatorId !== undefined && obj.operatorId !== null;
    const isQty = obj.qty !== undefined && obj.qty !== null;
    const isDate = obj.date !== undefined && obj.date !== null;
    const isBatch = obj.batch !== undefined && obj.batch !== null;
    
    const ret = isIngredientId && isStationId && isOperatorID && isQty && isDate && isBatch;

    return ret;
}

export function isBreadDefrostStats (obj: any): boolean {
    const isIngredientId = obj.ingredientId !== undefined && obj.ingredientId !== null;
    const isStationId = obj.stationId !== undefined && obj.stationId !== null;
    const isOperatorID = obj.operatorId !== undefined && obj.operatorId !== null;
    const isQty = obj.qty !== undefined && obj.qty !== null;
    const isDateStart = (obj.dateStart !== undefined && obj.dateStart !== null);
    const isDateEnd =  (obj.dateEnd !== undefined && obj.dateEnd !== null);
    const isBatch = obj.batch !== undefined && obj.batch !== null;
    
    const ret = isIngredientId && isStationId && isOperatorID  && isQty && isDateStart && isDateEnd && isBatch;

    return ret;
}

export interface IIngredientBakingInfo {
    ingredientId: string;
    ingredientName: string;
    date: Date;
    batch: string;
    qty: number;
    valabilityAfterBaking: string;
    operatorName: string;
}

export interface IIngredientsBakingInfo extends Array<IIngredientBakingInfo>{};

export interface IIngredientDefrostInfo {
    ingredientId: string;
    ingredientName: string;
    dateStart: Date;
    dateEnd: Date;
    batch: string;
    qty: number;
    valabilityAfterDefrost: string;
    operatorName: string;
}

export interface IIngredientsDefrostInfo extends Array<IIngredientDefrostInfo>{};
